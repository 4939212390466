import * as React from "react"

import Layout from "../components/Layout";


const NotFoundPage = () => (
  <Layout>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export const Head = () => (
    <>
         <title>ZipTips Privacy</title>
        <meta name="description" content="ZipTips privacy policy" />
        </>
  )
export default NotFoundPage